import React from 'react';
import { Link } from 'react-router-dom';
import { termsPolicyRenderer } from 'helpers/termsPolicyRenderer';

// Components
import Title from 'components/Title';

import styles from '../PolicyView/styles.module.css';

const policies = [
  {
    title: null,
    format: 'ul',
    content: [
      'This Privacy Policy explains how we and some of the companies we work with collect, use, share and protect information in relation to the Service, and your choices about the collection and use of your information.',
      'By using our Service you understand and agree that we are providing a platform for you to create content, including sand artwork, comments and other materials (“User Content”), to the Service and to share User Content publicly. This means that other Users may search for, see, use, or share any of your User Content that you make publicly available through the Service, consistent with the terms and conditions of this Privacy Policy and our Terms of Use (which can be found at <a href="/legal/terms">https://thisissand.com/legal/terms</a>).',
      'This Privacy Policy applies to all visitors, users, and others who access the Service (“Users”).',
    ],
  },
  {
    title: '1. Information We Collect',
    description: 'We collect the following types of information.',
    format: 'ul',
    content: [
      {
        title: 'Information you provide us directly:',
        content: [
          'Your username when you register for a Thisissand account.',
          'Your password and e-mail address if you register a Thisissand account using an e-mail address.',
          'Possible profile information that you provide for your user profile (e.g., profile picture).',
          'User Content (e.g., sand artwork, comments, and other materials) that you post to the Service.',
          'Communications between you and Thisissand. For example, we may send you Service-related e-mails (e.g., account verification, changes/updates to features of the Service, technical and security notices). Note that you may not opt out of Service-related e-mails.',
        ],
      },
      {
        title: 'Analytics information:',
        content: [
          'We use third-party analytics tools, such as Google Analytics, to help us measure traffic and usage trends for the Service. These tools collect information sent by your device or our Service, including features you use, and other information that assists us in improving the Service. We collect and use this analytics information with analytics information from other Users so that it cannot reasonably be used to identify any particular individual User.',
          'While we do use Google Analytics for collecting traffic and usage trends, all Google Analytics features related to personalisation and advertising, marketing and other commercial purposes, including signals data collection, are DISABLED.',
        ],
      },
      {
        title: 'Cookies and similar technologies:',
        content: [
          'Mobile Application uses cookies and local storage for storing data that is essential to the Service.',
          'Cookies and local storage are also used by the Mobile Application to collect information about how you use Thisissand. However, similar technologies like pixels or web beacons are NOT used for this purpose.',
        ],
      },
      {
        title: 'Log file information:',
        content: [
          'Mobile Application records log file information, including your interactions and navigation within the application, user session information, application version information, operating system information, device information, and other such information. When you contact us with features in the application, this information might be sent to our or third party servers to be analysed. The information allows for better customer service and improvement of the Service.',
          'When you use our Service, our servers automatically record certain log file information, including your web request, Internet Protocol (“IP”) address, user session information, application version information, operating system information, device information, referring / exit URLs, domain names, and other such information. The information allows for more accurate reporting and improvement of the Service.',
          'In case the Service should malfunction or if you contact us with features in the Service, diagnostic information about the user session might be sent to our or third party servers to be analysed.',
          'We do NOT collect information from e-mails sent to our Users such as which e-mails are opened.',
        ],
      },
      {
        title: 'Device identifiers:',
        content: [
          'Device identifiers are small data files or similar data structures stored on or associated with a device, which uniquely identify the device. Mobile Application does NOT access, collect, monitor, store on your device, or remotely store any device identifiers.',
        ],
      },
      {
        title: 'Metadata:',
        content: [
          'Metadata is usually technical data that is associated with User Content. For example, metadata can describe how, when and by whom a piece of User Content was collected and how that content is formatted.',
          'Users can add or may have metadata added to their User Content or other data. This makes your User Content richer and more searchable by us or by other Users.',
        ],
      },
    ],
  },
  {
    title: '2. How We Use Your Information',
    format: 'ul',
    content: [
      {
        title: 'In addition to some of the specific uses of information we describe in this Privacy Policy, we may use information that we receive to:',
        content: [
          'help you efficiently access your information after you log in',
          'remember information so you will not have to re-enter it during your visit or the next time you visit the Service',
          'provide, improve, test, and monitor the effectiveness of our Service',
          'develop and test new products and features',
          'monitor metrics such as total number of users, availability and traffic patterns',
          'diagnose or fix technology problems',
          'automatically update the Mobile Application on your device',
        ],
      },
      {
        title: 'For clarity, we do NOT use your information for the following purposes:',
        content: [
          'We do NOT use your information for advertisement or other forms of marketing.',
          'We do NOT use your information or information of other Users for analysing demographic patterns.',
        ],
      },
    ],
  },
  {
    title: '3. Sharing of Your Information',
    description:
      'We do NOT rent or sell your information to third parties outside Thisissand.',
    format: 'ul',
    content: [
      {
        title: 'Parties with whom you share your User Content:',
        content: [
          'Any information or content that you voluntarily disclose for posting to the Service, such as User Content, becomes available and searchable to the public.',
          'If you remove information that you posted to the Service, copies may remain viewable in cached and archived pages of the Service or in third party services such as search engines.',
        ],
      },
      {
        title: 'Parties with whom we may share your information:',
        content: [
          'We may share your information as well as information from tools like cookies and log files with third-party organisations and individuals that help us provide the Service to you (“Service Providers”). Our Service Providers will be given access to your information as is reasonably necessary to provide the Service under reasonable confidentiality terms.',
        ],
      },
      {
        title: 'What happens in the event of a change of control:',
        content: [
          'If we sell or otherwise transfer part or the whole of Thisissand or our assets to another organisation (e.g., in the course of a transaction like a merger, acquisition, bankruptcy, dissolution, liquidation), your information such as username and e-mail address, User Content and any other information collected through the Service may be among the items sold or transferred. You will continue to own your User Content. The buyer or transferee will have to honor the commitments we have made in this Privacy Policy.',
        ],
      },
    ],
  },
  {
    title: '4. How We Store Your Information',
    description: 'Storage and Processing:',
    format: 'ul',
    content: [
      'Your information collected through the Service may be stored and processed in the United States, in the Netherlands or any other country in which Thisissand, its Affiliates or Service Providers maintain facilities.',
      'Thisissand, its Affiliates, or Service Providers may transfer information that we collect about you, including personal information across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction.',
      'By using the Service you consent to the transfer of information to the United States, to the Netherlands or to any other country in which Thisissand, its Affiliates or Service Providers maintain facilities and the use and disclosure of information about you as described in this Privacy Policy.',
      'We use commercially reasonable safeguards to help keep the information collected through the Service secure and take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. However, Thisissand cannot ensure the security of any information you transmit to Thisissand or guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed.',
      'Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to e-mails between you and Thisissand, at all times.',
    ],
  },
  {
    title: '5. Your Choices About Your Information',
    format: 'ul',
    content: [
      {
        title: 'Your account information and profile/privacy settings:',
        content: [
          'Update your account at any time by logging in and changing your profile settings.',
          'As noted above, you may not opt out of Service-related communications (e.g., account verification, purchase and billing confirmations and reminders, changes/updates to features of the Service, technical and security notices).',
        ],
      },
      {
        title: 'How long we keep your User Content:',
        content: [
          'Following termination of your account, Thisissand, its Affiliates, or its Service Providers may retain information (including your profile information) and User Content up to 6 months after you have terminated your account for backup, archival, and/or audit purposes or for legal reasons.',
          'Terminate your account at <a href="/account/remove">https://thisissand.com/account/remove</a>.',
        ],
      },
    ],
  },
  {
    title: '6. Children’s Privacy',
    description:
      'The Service and its content are intended to be suitable for all ages. However, registering a user account requires that you are at least 13 years old. This means that Thisissand does not knowingly collect or solicit personal information from anyone under the age of 13. In the event that we learn that we have collected personal information from a child under the age of 13 (or a child under 13 years of age has registered a user account), we will delete that information and/or terminate the user account as quickly as possible.',
  },
  {
    title: '7. Other Websites And Services',
    description:
      'We are not responsible for the practices employed by any websites or services linked to or from our Service, including the information or content contained within them. Please remember that when you use a link to go from our Service to another website or service, the Privacy Policy does not apply to those third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorise to access your User Content. If you are using a third-party website or service and you allow them to access your User Content you do so at your own risk.',
  },
  {
    title: '8. How To Contact Us',
    description:
      'If you have any questions about this Privacy Policy or the Service, please send us an e-mail to <a href="mailto:support@thisissand.com">support@thisissand.com</a>.',
  },
  {
    title: '9. Changes to the Privacy Policy',
    description:
      'Thisissand may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your continued use of Thisissand or the Service after any modification to this Privacy Policy will constitute your acceptance of such modification.',
  },
];

const PolicyView = () => {
  return (
    <div className={styles.container}>
      <Link to="/legal/terms" className={styles.link}>
        Terms of Use
      </Link>
      <Title>Mobile Application Privacy Policy</Title>
      <p>This document describes the privacy policy for Thisissand mobile application (“Mobile Application Privacy Policy”, shortened in this document to “Privacy Policy”). If you are looking for the privacy policy for Thisissand browser application and other means of access (“General Privacy Policy”), navigate to <a href="/legal/general-privacy-policy">https://thisissand.com/legal/general-privacy-policy</a>.</p>
      <p>Thisissand website, Thisissand service, or any Thisissand applications (including browser and mobile applications) are made available by Beige Elephant (together, the “Service” or “Thisissand”). The Service is owned or controlled by Beige Elephant Ltd. (“Beige Elephant”, “we”, “us”, “our”, which also includes our employees and other staff).</p>
      <p>By accessing or using the Service with the Thisissand mobile application (“Mobile Application”) you agree to this Privacy Policy. If you access or use the Service with the Thisissand browser application or any other means besides the Mobile Application you agree to the General Privacy Policy. If you do not agree to this Privacy Policy, please do not access or use the Service.
      </p>
      <p className={styles.description}>This Privacy Policy is effective on 5th February, 2025.</p>
      {termsPolicyRenderer(policies)}
    </div>
  );
};

export default PolicyView;
