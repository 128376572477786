import React from 'react';
import { Link } from 'react-router-dom';
import { termsPolicyRenderer } from 'helpers/termsPolicyRenderer';

// Components
import Title from 'components/Title';

import styles from './styles.module.css';

const terms = [
  {
    title: 'Basic Terms',
    format: 'ol',
    content: [
      'Thisissand is available to users of all ages. To register a user account you must be at least 13 years old (see “Registered Accounts”)',
      'We like to keep things nice for all of our users. This is why you may not post violent, nude, discriminatory, unlawful, infringing, hateful or pornographic content via the Service. Do not use offensive or defamatory language in the Service.',
      'You are responsible for any activity that occurs through your account. Beige Elephant prohibits the creation of and you agree that you will not create an account for anyone other than yourself. You also represent that all information you provide or provided to Beige Elephant upon registration and at all other times will be true, accurate, current and complete and you agree to update your information as necessary to maintain its truth and accuracy.',
      'You are responsible for keeping your password secret and secure.',
      'You may be able to purchase additional features on the Service.',
      'Act nice. Do not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people on the Service. Do not post private or confidential information via the Service.',
      'Obey the law. You may not use the Service for any illegal or unauthorised purpose, including but not limited to, copyright laws.',
      'You are solely responsible for any data, text, information, images, works of authorship, links and other content (collectively “User Content”) that you submit, post or display on or via the Service.',
      'Do not spam other users of the Service or the Service itself. Do not interfere with or disrupt the Service. Do not attempt to restrict another user from using or enjoying the Service.',
      'You must not create accounts with the Service through unauthorised means, including but not limited to, by using an automated device, script, bot, spider, crawler or scraper.',
      'Violation of these Terms of Use may result in revocation of your right to use the Service (“ban”) and/or termination of your Thisissand account.',
      'You understand and agree that Beige Elephant is not responsible for the User Content posted on the Service and you use the Service at your own risk. If you violate the letter or spirit of these Terms of Use or otherwise create legal or other risks for Beige Elephant, we may cease providing all or part of the Service to you.',
    ],
  },
  {
    title: 'General Conditions',
    format: 'ol',
    content: [
      'Beige Elephant reserves the right to modify or terminate the Service or your access to the Service for any reason at any time without notice.',
      'You may terminate your Thisissand account by completing the form available here: <a href="/account/remove">https://thisissand.com/account/remove</a>. If we terminate your account or you use the form detailed above to terminate your account, your artwork, comments, likes, and all other data will no longer be accessible through your Thisissand account (e.g., users will not be able to navigate to your username and view your User Content), but those materials and data may persist and appear within the Service (e.g., if the User Content is still cached in a content delivery network). It is also possible that a third party may have submitted or shared your User Content on the Service or outside of it. We may keep your information up to 6 months after you have terminated your account for backup, archival, and/or audit purposes or for legal reasons.',
      'Upon termination, all licences and other rights granted to you in these Terms of Use will immediately cease.',
      'We reserve the right to change these Terms of Use (“Updated Terms”) from time to time. We will notify our users with a popup or by posting them on our Service. You agree that your use of the Service after the effective date of the Updated Terms constitutes your agreement to the Updated Terms. Therefore, you should review these Terms of Use and any Updated Terms before using the Service.',
      'We reserve the right to refuse access to the Service to anyone for any reason at any time and the right to change your username for any reason (including if we deem it offensive).',
      'We have no obligation to remove, edit, block, and/or monitor User Content or accounts containing User Content that we determine violates these Terms of Use, but may do so.',
      'You are responsible for your interaction with other users of the Service. Beige Elephant is not responsible or liable for the conduct of any user. Beige Elephant reserves the right, but has no obligation, to monitor or become involved in disputes between you and other users. Exercise common sense and your best judgment when interacting with others, including when you submit or post User Content or any personal or other information.',
      'Thisissand may contain links to services by third parties. The Service also may include third-party content that we do not control, maintain or endorse. We may also offer you functionalities which enable you to e.g. share your User Content on services by third parties or otherwise permit interactions between Thisissand and services by third parties. We are not responsible or liable for the services of third parties. This includes possible third party applications which enable you to share your User Content on other websites.',
      'You are responsible for all data charges you incur through use of the Service.',
      'We prohibit crawling, scraping, machine learning ingestion, caching or otherwise accessing any content, including but not limited to user profiles and artwork, on the Service via automated means. The sole exception to this is that traditional search engines are allowed to crawl and cache content on the Service via automated means. Even then the content collected this way by the traditional search engines shall not be passed to other types of automatons, including but not limited to machine learners and analysers (except as may be the result of indirect passing via publicly available search results pages of traditional search engines).'
    ],
  },
  {
    title: 'Rights',
    format: 'ol',
    content: [
      'Beige Elephant does not claim ownership of any User Content that you post on or through the Service. Instead, you hereby grant us a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide licence to use the User Content that you post on or through the Service.',
      'Make sure you have the right to share the works. You represent and warrant that: (i) you own or otherwise have the right to the User Content you submit to the Service; (ii) your User Content does not infringe on the rights of any third party, (iii) you have the legal right and capacity to enter into these Terms of Use in your jurisdiction.',
      'We have our own content in the Service as well. The Service contains content owned or licensed by Beige Elephant (“Our Content”). Our Content is protected by copyright, trademark, patent, trade secret and other laws. We own all rights in Our Content and the Service.',
      'Although it is Beige Elephant’s intention for the Service to be available as much as possible, there will be occasions when the Service may be interrupted. Please be patient and let us know if things are broken. Beige Elephant will not be liable to you for any modification, suspension, or discontinuation of the Services, or the loss of any User Content. You also acknowledge that the Internet may be subject to breaches of security and that the submission of User Content or other information may not be secure.',
      'We have a legal process for reporting and removing illegal content. You agree that Beige Elephant is not responsible for and does not endorse, User Content posted within the Service. Beige Elephant does not have any obligation to prescreen, monitor, edit, or remove any User Content. If your User Content violates these Terms of Use, you may bear legal responsibility for that User Content.',
      'Except as otherwise described in the Service’s Privacy Policy, available at <a href="/legal/privacy">https://thisissand.com/legal/privacy</a>, any User Content will be public.',
    ],
  },
  {
    title: 'Registered Accounts',
    format: 'ol',
    content: [
      'If you are over 13 years old, you may sign up and register a Thisissand account. Registering a Thisissand account is free. Thisissand account enables you to connect your User Content to your account and show your content on your profile page. Access to certain areas or functionalities of the Service may require you to register an account.',
      'When registering, you must choose a valid username. We may also require you to give us your e-mail address and a password, depending on what method you want to use to register.',
      'While Thisissand does not collect your personal information (except as otherwise described in these Terms of Use or the Privacy Policy), people may be able to identify you by your account.',
      'You may terminate your account at any time at <a href="/account/remove">https://thisissand.com/account/remove</a>. Do note that even if you terminate your account, we may keep your information up to 6 months after you have terminated your account for backup, archival, and/or audit purposes or for legal reasons. If you terminate your account, the User Content you have submitted to the Service may be lost. It is also possible that a third party may have submitted or shared your User Content outside the Service.',
      'You will get to keep the possible additional features you have purchased in the Service even if you terminate your account.',
    ],
  },
  {
    title: 'Reporting Copyright and Other IP Violations',
    format: 'ol',
    content: [
      'We respect other people’s rights, and expect you to do the same.',
      'We provide you with tools to help you protect your content. To learn more about how to report claims of intellectual property infringement, please send e-mail to dmca@thisissand.com according to the instructions below.',
      'If you want to report a copyright violation, please include in your report:',
      [
        'Identification (e.g. URL or a link) of the work claimed to have been infringed or a list of such works',
        'Identification (e.g. URL or a link) of the infringing material so that we are able to find and identify the infringing material',
        'Your contact information',
        'A statement that you have a good faith belief that use of the material in the manner complained of is unauthorised or illegal',
        'A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorised to act on behalf of the owner of an exclusive right that is allegedly infringed',
        'A physical or electronic signature of a person authorised to act on behalf of the owner of an exclusive right that is allegedly infringed',
      ],
      'If you repeatedly infringe other people’s intellectual property rights, we may disable your account or revoke your access to the Service.',
    ],
  },
  {
    title: 'Disclaimer of Warranties',
    format: 'ul',
    content: [
      {
        title: 'We provide the Service “As is”',
        content: [
          'We provide the Service (and Our Content) on an “as is”, “as available” and “with all faults” basis. We do not make any representations, warranties or endorsements of any kind whatsoever, express or implied, as to (a) the Service; (b) Our Content; (c) User Content; or (d) security associated with the transmission of information to us or via the Service. We disclaim all warranties, express or implied (e.g. the warranties of merchantability, fitness for a particular purpose, non-infringement, title, custom, trade, quiet enjoyment, system integration and freedom from computer viruses).',
        ],
      },
      {
        title: 'Our Service may contain errors',
        content: [
          'While we do our best to ensure that the Service is error-free, we do not represent or warrant that the Service will be error-free or uninterrupted, that defects will be corrected, or that the servers we use are are error-free or free from viruses or other harmful content. We do not make any representations or warranties that the information, including any instructions, on the Service is accurate, complete or useful. You use the Service at your own risk. We do not warrant that the use of Service is lawful in any particular jurisdiction, and we specifically disclaim such warranties.',
        ],
      },
      {
        title: 'Miscellaneous on warranties',
        content: [
          'Some jurisdictions limit or do not allow the disclaimer of implied or other warranties. In this case the above disclaimer may not apply to you or may apply to your only partly.',
          'By accessing or using the Service you represent and warrant that your activities are lawful in every jurisdiction where you access or use the Service.',
          'We do not endorse content and specifically disclaim any responsibility or liability to any person or entity for any loss or damage.',
        ],
      },
    ],
  },
  {
    title: 'Limitation of Liability; Waiver',
    format: 'ul',
    content: [
      {
        title: 'We are not liable to you for losses or damages',
        content: [
          'We will not be liable to you or anyone else for any loss or damages of any kind.',
          'In no event will our total liability to you for all damages, losses or causes or action exceed one hundred euros (100.00 EUR).',
        ],
      },
      {
        title: 'You do not have the right to an injunction',
        content: [
          'Should you incur any damages, losses or injuries that arise out of our action or inaction, the damages are not irreparable or sufficient to entitle you to an injuction preventing any exploitation of any websites, services, properties, products or other content owned or controlled by us. You do not have the right to enjoin or restrain our activities regarding any websites, properties, products, services or other content owned or controlled by us.',
        ],
      },
      {
        title: 'You may be waiving your right to yet unknown claims',
        content: [
          'You understand that you may be waiving your right to claims that are at this time unknown or unsuspected.',
          'We are not responsible for the actions, content, information, or data of third parties, and you release us, our directors, officers, employees, and agents from any claims and damages, known and unknown, arising out of or in any way connected with any claim you have against any such third parties.',
        ],
      },
    ],
  },
  {
    title: 'Indemnification',
    subtitle: 'You will defend us in court',
    format: 'roman',
    content: [
      {
        title:
          'If someone took us to court (or used other legal means against us) because of the following:',
        content: [
          'your User Content or your access to or use of the Service;',
          'your breach or alleged breach of these Terms of Use;',
          'your violation of any third-party right, including any intellectual property right, publicity, confidentiality, property or privacy right;',
          'your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any governmental and quasi-governmental authorities, including, without limitation, all regulatory, administrative and legislative authorities; or',
          'any misrepresentation made by you,',
        ],
      },
    ],
    summary:
      'You agree to defend, indemnify and hold us harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable attorney’s fees and costs, should we require it.',
  },
  {
    title: null,
    format: null,
    subtitle: 'You will cooperate with us if we so require',
    content: [],
    summary:
      'You will cooperate as fully required by us in the defence of any claim. We reserve the right to assume the exclusive defence and control of any matter subject to indemnification by you, and you will not in any event settle any claim without our prior written consent.',
  },
  {
    title: 'Time Limitation on Claims',
    format: null,
    content: [],
    summary:
      'You agree that any claim you may have arising out of or related to your relationship with us must be filed within one year after such claim arose; otherwise, your claim is permanently barred.',
  },
  {
    title: 'Governing Law & Venue',
    format: 'ul',
    content: [
      'If a dispute arises between you and us, we strongly encourage you to first contact us directly to seek a solution by contacting our customer service at <a href="mailto:support@thisissand.com">support@thisissand.com</a>.',
      'You agree that all disputes between you and us shall be governed by the laws of Finland, without regard to conflict of law provisions. You agree that any claim or dispute you may have against Beige Elephant must be resolved exclusively by a court located in Helsinki, Finland.',
    ],
  },
  {
    title: 'Entire Agreement',
    format: 'ul',
    content: [
      'These Terms of Use constitute the entire agreement between you and us and governs your use of the Service. If you have made any prior agreements with us, these Terms of Use supersede them.',
      'You do not have the right to assign these Terms of Use or the rights or obligations they convey to anyone without our prior written consent. If you do so without our prior written consent, such assignment will be null and void. We may assign these Terms of Use or any rights hereunder without your consent.',
      'If a part of these Terms of Use are found by a court of competent jurisdiction to be invalid or otherwise unenforceable, you and we nevertheless agree that such portion will be deemed severable from these Terms of Use and will not affect the validity and enforceability of the remainder of these Terms of Use and the remainder remains in full force and effect.',
      'Neither the course of conduct between the parties nor trade practice will modify the Terms of Use. These Terms of Use do not confer any third-party beneficiary rights.',
    ],
  },
  {
    title: 'Territorial Restrictions',
    format: 'ul',
    content: [
      'We have the right to limit the availability of the Service or any portion of it to any person, geographic area, or jurisdiction, at any time and at our sole discretion, and to limit the quantities of any content, program, product, service or other feature that we provide.',
      'These Terms of Use were written in English. To the extent any translated version of these Terms of Use conflicts with the English version, the English version controls.',
    ],
  },
];

const TermsView = () => {
  return (
    <div className={styles.container}>
      <Link to="/legal/privacy" className={styles.link}>
        Privacy Policy
      </Link>
      <Title>Terms of Use</Title>
      <p>Thisissand website, Thisissand service, or any Thisissand applications (including browser and mobile applications) are made available by Beige Elephant (together, the “Service” or “Thisissand”). The Service is owned or controlled by Beige Elephant Ltd. (“Beige Elephant”, “we”, “us”, “our”, which also includes our employees and other staff).</p>
      <p>By accessing or using the Service, however accessed, you agree to be bound by these terms of use (“Terms of Use”). These Terms of Use affect your legal rights and obligations. If you do not agree to be bound by all of these Terms of Use, please do not access or use the Service.</p>
      <p>These Terms of Use are effective on 9th February, 2025.</p>
      {termsPolicyRenderer(terms)}
    </div>
  );
};

export default TermsView;
